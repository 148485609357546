@import url(https://fonts.googleapis.com/css2?family=Aref+Ruqaa:wght@400;700&family=Cinzel:wght@400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Caveat&family=Shadows+Into+Light&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Handlee&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Cormorant+Garamond:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&family=Merienda:wght@400;700&display=swap);
body {
  margin: 0;
  padding:0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#nav-head {
  text-align: center;
}

button {
  font-family: "Handlee", cursive;
}

input,
label,
textarea {
  font-family: "Caveat", cursive;
}

p {
  font-family: "Cormorant Garamond", serif;
}

Nav {
  font-family: "Merienda", cursive;
}

h1,
h2,
h4,
h5 {
  font-family: "Cinzel", serif;
}

h3 {
  font-family: "Aref Ruqaa", serif;
  font-weight: 700;
}

a {
  text-decoration: none;
}

body,
.App {
  margin: 0;
  padding: 0;
}

video {
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  -webkit-transform: translateX(calc((100% - 100vw) / 2));
          transform: translateX(calc((100% - 100vw) / 2));
  opacity: 0.7;
  z-index: -1;
}

img {
  /* transform: translateX(calc((100% - 100vw) / 2)); */
  max-width: 100%;
  max-height: 100%;
}

.cover-image {
  background-size: cover;
}

.opac {
  opacity: 0.5;
}

.containerImg {
  position: relative;
}

.pointer:hover,
.containerImg:hover {
  cursor: pointer;
}

.center {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  font-size: 1.5rem;
  color: beige;
  font-weight: bold;
  background-color: rgba(0, 0, 0, 0.3);
}

.imgWrapper {
  position: relative;
}

.imgText {
  width: 100%;
  height: 100%;
}

#nav-head {
}
#nav-menu {
}
#main {
  width: 100%;
  padding-bottom: 2rem;
}

